import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import POne from '../../assets/images/projects/Project1.png'
import PTwo from '../../assets/images/projects/Project2.png'
import PThree from '../../assets/images/projects/Project3.png'

const swData = [
    {
        img: POne,
        title: 'Community Outreach',
        location: 'Ijebu-Ode',
    },
    {
        img: PTwo,
        title: 'Women Empowerment',
        location: 'Remo North',
    },
    {
        img: PThree,
        title: 'Secondary Schools Refurbishment',
        location: 'Ota LGA',
    },
]

function Projects() {
    return (
        <section className='mt-[120px]'>
            <div className='flex lg:flex-row flex-col w-full gap-x-16 px-3 xl:px-0'>
                <div className='space-y-3 lg:w-1/6 lg:mb-0 mb-12'>
                    <div className='p-2 hidden lg:block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.5 11H4.6C4.03995 11 3.75992 11 3.54601 11.109C3.35785 11.2049 3.20487 11.3578 3.10899 11.546C3 11.7599 3 12.0399 3 12.6V21M16.5 11H19.4C19.9601 11 20.2401 11 20.454 11.109C20.6422 11.2049 20.7951 11.3578 20.891 11.546C21 11.7599 21 12.0399 21 12.6V21M16.5 21V6.2C16.5 5.0799 16.5 4.51984 16.282 4.09202C16.0903 3.71569 15.7843 3.40973 15.408 3.21799C14.9802 3 14.4201 3 13.3 3H10.7C9.57989 3 9.01984 3 8.59202 3.21799C8.21569 3.40973 7.90973 3.71569 7.71799 4.09202C7.5 4.51984 7.5 5.0799 7.5 6.2V21M22 21H2M11 7H13M11 11H13M11 15H13" stroke="#008000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <h3 className='font-semibold text-center lg:text-left'>Our projects</h3>
                    <p className='underline text-primary text-base font-bold text-center lg:text-left'>See all projects</p>
                </div>


                <div className='lg:w-5/6'>
                    <Swiper
                        navigation={true}
                        modules={[Navigation]}
                        // spaceBetween={30}
                        // slidesPerView={5}
                        spaceBetween={270}
                        slidesPerView={1}
                        autoplay={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 270,
                            },
                            768: {
                                slidesPerView: 1,
                                spaceBetween: 290,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 370,
                            },

                        }}
                        // onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                        className={`relative w-full`}
                    >
                        {swData.map((res, index) => (
                            <SwiperSlide key={index}>
                                <div className='xl:w-[700px] md:w-[580px] lg:h-[370px] w-full'>
                                    <img
                                        alt={`OIC ${res.title}`}
                                        src={res.img}
                                        className='w-full h-full'
                                    />

                                </div>
                                <div className='mt-3 md:text-left text-center'>
                                    <p className='text-2xl font-bold'>{res.title}</p>
                                    <p className='text-xs font-normal'>{res.location}</p>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

            </div>
        </section>
    )
}

export default Projects
