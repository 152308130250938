import React from 'react'

function Hero() {
    return (
        <section className='2xl:mt-[130px] lg:mt-[100px] mt-[130px] max-w-[1010px] mx-auto'>
            <h1 className='font-normal sm:text-6xl text-4xl text-center sm:leading-[85px]'>Lead the Next Generation of Ogun State Leaders</h1>

            <div className='sm:flex block justify-center items-center sm:mt-5 mt-10 text-center mx-auto gap-x-5'>
                <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg font-bold'>Showcase Your Leadership</button>
                <p className='font-bold mt-2 sm:mt-0'>Be Inclusive</p>
            </div>
        </section>
    )
}

export default Hero