import React from 'react'
import Logo from '../../assets/logos/impact.png'

function Footer() {
    return (
        <footer className='2xl:max-w-[1440px] max-w-[1280px] mx-auto mt-[180px] mb-[25px]'>

            <div className='flex flex-col md:flex-row justify-between md:items-start px-3 2xl:px-0 gap-y-8 items-center'>
                <div className='w-[220px]'>
                    <img src={Logo} alt="" className='hidden md:block' />
                    <p className='text-sm mt-2 tracking-wider text-center md:text-left'>Speak Up, Lead On</p>
                </div>

                <div>
                    <p className='text-sm tracking-wider hidden sm:block'>Site map</p>
                    <ul className='text-[11px] space-y-1.5 mt-3'>
                        <li>About Us</li>
                        <li>Meet the team</li>
                        <li>Events</li>
                        <li>Articles</li>
                        <li>Contact</li>
                    </ul>
                </div>

                <div className='space-y-4'>
                    <p className='text-sm tracking-wider'>Sign Up for Newsletter</p>
                    <p className='text-[11px]'>Join thousands of other youths for news and updates</p>

                    <form className='flex gap-x-3'>
                        <input type="email" name="email" id="email" placeholder='Enter email address' className='text-[11px] border border-black rounded-lg px-2 ' />
                        <button className='bg-primary text-secondary px-3 py-[6px] rounded-lg font-bold'>Subscribe</button>
                    </form>
                </div>
            </div>

            <div className='flex flex-col sm:flex-row justify-between items-center mt-8 bg-[#D9D9D9] px-[54px] py-3 rounded-3xl mx-3 sm:mx-3 gap-y-4'>
                <p className='text-center sm:text-left text-xs sm:text-sm'>Copyright 2023 Ogun State Impact Circle</p>

                <div className='flex items-center gap-x-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M13 10H16V13H13V20H10V13H7V10H10V8.745C10 7.556 10.374 6.054 11.118 5.233C11.862 4.41 12.791 4 13.904 4H16V7H13.9C13.402 7 13 7.402 13 7.899V10Z" fill="black" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18.89 7.01197C19.698 6.51597 20.233 5.83897 20.495 4.97797C19.709 5.39497 18.926 5.68097 18.144 5.83897C17.441 5.08297 16.551 4.69897 15.484 4.69897C14.441 4.69897 13.56 5.06497 12.841 5.77697C12.126 6.49397 11.765 7.36497 11.765 8.38197C11.765 8.69097 11.804 8.96697 11.882 9.20097C8.80599 9.09597 6.25999 7.81997 4.25399 5.36397C3.91399 5.96497 3.74399 6.57697 3.74399 7.20997C3.74399 8.51097 4.29299 9.54197 5.38899 10.299C4.76399 10.246 4.21299 10.088 3.74399 9.82898C3.74399 10.758 4.01699 11.534 4.56399 12.217C5.11299 12.893 5.81799 13.324 6.67899 13.508C6.36699 13.588 6.03799 13.626 5.69999 13.626C5.38799 13.626 5.16699 13.6 5.03599 13.543C5.26599 14.3 5.69999 14.914 6.32699 15.384C6.95199 15.856 7.67099 16.105 8.47899 16.127C7.14699 17.172 5.62399 17.689 3.90099 17.689C3.47899 17.689 3.17999 17.683 2.99899 17.651C4.69599 18.753 6.58499 19.3 8.67499 19.3C10.814 19.3 12.704 18.758 14.349 17.674C15.994 16.596 17.208 15.266 17.988 13.7C18.772 12.136 19.16 10.508 19.16 8.80797V8.33997C19.918 7.76997 20.531 7.12797 21 6.41897C20.32 6.71197 19.617 6.91097 18.89 7.01197Z" fill="black" />
                    </svg>

                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M16.3748 3.24984C17.5342 3.25331 18.6451 3.71539 19.4648 4.53517C20.2846 5.35495 20.7467 6.46582 20.7502 7.62516V16.3748C20.7467 17.5342 20.2846 18.6451 19.4648 19.4648C18.6451 20.2846 17.5342 20.7467 16.3748 20.7502H7.62516C6.46582 20.7467 5.35495 20.2846 4.53517 19.4648C3.71539 18.6451 3.25331 17.5342 3.24984 16.3748V7.62516C3.25331 6.46582 3.71539 5.35495 4.53517 4.53517C5.35495 3.71539 6.46582 3.25331 7.62516 3.24984H16.3748ZM16.3748 1.5H7.62516C4.25625 1.5 1.5 4.25625 1.5 7.62516V16.3748C1.5 19.7437 4.25625 22.5 7.62516 22.5H16.3748C19.7437 22.5 22.5 19.7437 22.5 16.3748V7.62516C22.5 4.25625 19.7437 1.5 16.3748 1.5Z" fill="black" />
                        <path d="M17.6873 7.62524C17.4278 7.62524 17.174 7.54827 16.9582 7.40405C16.7423 7.25983 16.5741 7.05484 16.4748 6.81502C16.3754 6.57519 16.3494 6.31129 16.4001 6.05669C16.4507 5.80209 16.5757 5.56822 16.7593 5.38467C16.9428 5.20111 17.1767 5.07611 17.4313 5.02546C17.6859 4.97482 17.9498 5.00081 18.1896 5.10015C18.4294 5.19949 18.6344 5.36772 18.7787 5.58356C18.9229 5.7994 18.9998 6.05316 18.9998 6.31274C19.0002 6.48521 18.9665 6.65605 18.9007 6.81545C18.8349 6.97486 18.7382 7.1197 18.6163 7.24165C18.4943 7.3636 18.3495 7.46026 18.1901 7.52609C18.0307 7.59192 17.8598 7.62562 17.6873 7.62524Z" fill="black" />
                        <path d="M12 8.49984C12.6923 8.49984 13.369 8.70512 13.9446 9.08973C14.5202 9.47433 14.9688 10.021 15.2337 10.6605C15.4986 11.3001 15.568 12.0039 15.4329 12.6828C15.2978 13.3618 14.9645 13.9855 14.475 14.475C13.9855 14.9645 13.3618 15.2978 12.6828 15.4329C12.0039 15.568 11.3001 15.4986 10.6606 15.2337C10.021 14.9688 9.47433 14.5202 9.08973 13.9446C8.70513 13.369 8.49985 12.6923 8.49985 12C8.50084 11.072 8.86992 10.1823 9.52611 9.52611C10.1823 8.86992 11.072 8.50084 12 8.49984ZM12 6.75C10.9617 6.75 9.94662 7.05791 9.08326 7.63478C8.2199 8.21166 7.54699 9.0316 7.14963 9.99091C6.75227 10.9502 6.64831 12.0058 6.85088 13.0242C7.05345 14.0426 7.55347 14.9781 8.28769 15.7123C9.02192 16.4465 9.95738 16.9466 10.9758 17.1491C11.9942 17.3517 13.0498 17.2477 14.0091 16.8504C14.9684 16.453 15.7883 15.7801 16.3652 14.9167C16.9421 14.0534 17.25 13.0384 17.25 12C17.25 10.6076 16.6969 9.27226 15.7123 8.28769C14.7277 7.30312 13.3924 6.75 12 6.75Z" fill="black" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_12_309)">
                            <path d="M12.4219 4.5C15.4828 4.5 17.986 4.55156 20.3016 4.65469H20.3344H20.3672C21.45 4.65469 22.3359 5.68594 22.3359 6.95625V7.00781L22.3406 7.05938C22.4485 8.65313 22.5 10.3078 22.5 11.9766C22.5047 13.6453 22.4485 15.3 22.3406 16.8937L22.3359 16.9453V16.9969C22.3359 17.6438 22.1156 18.2438 21.7078 18.6891C21.3422 19.0922 20.8641 19.3172 20.3672 19.3172H20.3297H20.2922C17.8125 19.4344 15.1922 19.4953 12.4922 19.4953C12.3281 19.4953 12.1594 19.4953 11.9953 19.4953C11.8266 19.4953 11.6578 19.4953 11.4891 19.4953C8.7797 19.4953 6.15939 19.4344 3.69845 19.3219H3.66095H3.62345C3.12658 19.3219 2.65314 19.0969 2.28751 18.6937C1.88439 18.2484 1.65939 17.6484 1.65939 17.0016V16.95L1.6547 16.8984C1.5422 15.3 1.49064 13.6453 1.50001 11.9906V11.9859V11.9813C1.49533 10.3266 1.54689 8.67656 1.6547 7.07812L1.65939 7.02656V6.975C1.65939 5.7 2.54064 4.66406 3.62345 4.66406H3.65626H3.68907C6.00939 4.55625 8.51251 4.50937 11.5735 4.50937H11.9953H12.4219M12.4219 3C12.2813 3 12.1406 3 12 3C11.8594 3 11.7188 3 11.5781 3C8.87814 3 6.22501 3.0375 3.62814 3.15469C1.71564 3.15469 0.164075 4.85625 0.164075 6.96563C0.0468877 8.64375 -0.00467481 10.3125 1.26871e-05 11.9859C-0.00467481 13.6594 0.0422002 15.3281 0.159388 17.0016C0.159388 19.1109 1.71095 20.8266 3.62345 20.8266C6.1922 20.9484 8.81251 21.0047 11.4891 21.0047C11.6578 21.0047 11.8313 21.0047 12 21.0047C12.1688 21.0047 12.3375 21.0047 12.5016 21.0047C15.1781 21.0047 17.7985 20.9484 20.3719 20.8266C22.2891 20.8266 23.8406 19.1109 23.8406 17.0016C23.9531 15.3281 24.0047 13.6547 24 11.9813C24.0047 10.3078 23.9531 8.63906 23.8406 6.96094C23.8406 4.85156 22.2891 3.15938 20.3719 3.15938C17.775 3.0375 15.1266 3 12.4219 3Z" fill="black" />
                            <path d="M9.70312 16.5844V7.37817L16.5 11.9813L9.70312 16.5844Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_12_309">
                                <rect width="24" height="24" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
            </div>

        </footer>
    )
}

export default Footer
