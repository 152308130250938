import React from 'react'
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import Slide from '@mui/material/Slide';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


function CDialog({ children, open, title, action, setModal }) {
    return (
        <Dialog

            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setModal(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogContent className='w-[320px] sm:w-[600px]'>
                <div className='flex justify-between'>
                    <h4 className='text-2xl font-bold'>{title}</h4>
                    <button onClick={() => setModal(false)}>x</button>
                </div>
                <hr />
                {children}
            </DialogContent>
            {/* <DialogActions>
        
      </DialogActions> */}
        </Dialog>
    )
}

export default CDialog