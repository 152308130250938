import React from 'react'

import POne from '../../assets/images/projects/Project1.png'
import PTwo from '../../assets/images/projects/Project2.png'
import PThree from '../../assets/images/projects/Project3.png'

const swData = [
    {
        img: POne,
        title: 'Nigeria’s most respected Lending CEO',
        location: 'Nigerian Industry CEO awards',
        year: '2024',
    },
    {
        img: PTwo,
        title: '1000 for 1000 Affordable Housing Design Competition',
        location: 'Shelter Nigeria',
        year: '2024',
    },
    {
        img: PThree,
        title: '1st Place - South Leg on Apartment Design Competition',
        location: 'Lagos State Government',
        year: '2023',
    },
    {
        img: POne,
        title: 'Lorem Ipsum is simply dummy text of the typesetting industry.',
        location: 'Ogun State Government',
        year: '2023',
    },
    {
        img: PThree,
        title: 'Roots in a piece of classical Latin literature from 45 BC,',
        location: 'Federal Republic of Nigeria',
        year: '2023',
    },
]


function Awards() {
    return (
        <section className='mt-[120px] mb-[300px]'>
            <div className='flex lg:flex-row flex-col w-full gap-x-16 px-3 xl:px-0'>
                <div className='space-y-3 xl:w-1/6 lg:mb-0 mb-12'>
                    <div className='p-2 hidden lg:block'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z" stroke="#008000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <h3 className='font-semibold text-center lg:text-left'>Awards / Features</h3>
                    <p className='underline text-primary text-base font-bold text-center lg:text-left'>See all awards</p>
                </div>


                <div className='xl:w-5/6'>
                    <div className='flex gap-x-7'>
                        <p>Awards</p>
                        <p>Features</p>
                    </div>

                    <div className='mt-9 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-6'>
                        {swData.map((res, index) => (
                            <div key={index} className='xl:w-[250px]'>
                                <div className='w-full md:h-[180px]'>
                                    <img
                                        alt={`OIC ${res.title}`}
                                        src={res.img}
                                        className='w-full h-full'
                                    />

                                </div>
                                <div className='mt-3'>
                                    <p className='text-base font-bold'>{res.title}</p>
                                    <p className='text-sm font-normal text-secondary-text'>{res.location}</p>
                                    <p className='text-sm font-normal text-secondary-text'>{res.year}</p>
                                </div>
                            </div>
                        ))}

                    </div>

                </div>

            </div>
        </section>
    )
}

export default Awards