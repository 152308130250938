import React from 'react'
import { Link } from 'react-router-dom'

function Activities() {
    return (
        <div className='mt-[100px]'>
            <h2 className='text-black text-center font-semibold text-2xl sm:text-5xl'>Activities</h2>

            <div className='mt-[50px] flex sm:flex-row flex-col items-center sm:items-start justify-center gap-x-12'>

                <Link to={`/about-us`} className='pl-5 pr-[30px] py-[30px] shadow-card rounded-lg max-w-[250px] h-[350px]'>
                    <div className='space-y-4'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M12 20V10" stroke="#6F6E6E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M18 20V4" stroke="#6F6E6E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M6 20V16" stroke="#6F6E6E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <p className='text-base font-medium'>Projects</p>
                        <p className='text-xs font-normal text-secondary-text'>The Ogun State Impact Circle focuses on different projects and initiatives that create meaningful experiences and learning opportunities for the youth involved. Our vision seeks to create a more inclusive tomorrow, and our project initiatives develop and empower the youth to become future leaders within the community.</p>
                    </div>
                </Link>

                {/* second card */}
                <Link to={`/blog`} className='pl-5 pr-[30px] py-[30px] shadow-card rounded-lg max-w-[250px] h-[310px]'>
                    <div className='space-y-4'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_8_175)">
                                    <path d="M3 10.5H18V9H3V10.5ZM11.25 19.5H16.5V18H11.25V19.5ZM11.25 16.5H18V15H11.25V16.5ZM11.25 13.5H18V12H11.25V13.5ZM3 19.5H9.75V12H3V19.5ZM21 7.5V4.5H0V21C0 21 0 24 3 24H21.75C21.75 24 24 23.9535 24 21V7.5H21ZM3 22.5C1.5 22.5 1.5 21 1.5 21V6H19.5V21C19.5 21.6908 19.713 22.1685 20.007 22.5H3Z" fill="#6F6E6E" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_8_175">
                                        <rect width="24" height="24" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <p className='text-base font-medium'>News & Policies</p>
                        <p className='text-xs font-normal text-secondary-text'>The Ogun State Impact Circle keeps its members and followers informed on the current events within the movement and the state. Our News and Blog section provides the latest updates on our progress and allows us to share our stories with the world.</p>
                    </div>
                </Link>
            </div>

            <div className='flex sm:flex-row flex-col items-center sm:items-start justify-center gap-x-12'>
                <Link to={`/events`} className='mt-7 pl-5 pr-[30px] py-[30px] shadow-card rounded-lg max-w-[250px] h-[350px]'>
                    <div className='space-y-4'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M16.5564 19.0468H7.44364C6.49281 19.0468 5.722 18.276 5.722 17.3252V8.21248C5.722 7.26165 6.4928 6.49084 7.44364 6.49084H16.5564C17.5072 6.49084 18.278 7.26164 18.278 8.21248V17.3252C18.278 18.276 17.5072 19.0468 16.5564 19.0468Z" stroke="#6F6E6E" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.4436 18.2969C6.90771 18.2969 6.47192 17.8611 6.47192 17.3254V8.21265C6.47192 7.67676 6.90771 7.24097 7.4436 7.24097H16.5564C17.0923 7.24097 17.5281 7.67676 17.5281 8.21265V17.3254C17.5281 17.8611 17.0923 18.2969 16.5564 18.2969H7.4436Z" stroke="#6F6E6E" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M9.29049 6.49089H7.94426V5.32802C7.94426 5.12097 8.11211 4.95312 8.31916 4.95312H8.91559C9.12264 4.95312 9.29049 5.12097 9.29049 5.32802V6.49089Z" stroke="#6F6E6E" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M16.0557 6.49089H14.7095V5.32802C14.7095 5.12097 14.8773 4.95312 15.0844 4.95312H15.6808C15.8879 4.95312 16.0557 5.12097 16.0557 5.32802V6.49089Z" stroke="#6F6E6E" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M8.90049 10.6781C9.20684 10.6781 9.45518 10.4298 9.45518 10.1234C9.45518 9.81707 9.20684 9.56873 8.90049 9.56873C8.59415 9.56873 8.34581 9.81707 8.34581 10.1234C8.34581 10.4298 8.59415 10.6781 8.90049 10.6781Z" fill="#6F6E6E" />
                                <path d="M12 10.6781C12.3063 10.6781 12.5547 10.4298 12.5547 10.1234C12.5547 9.81707 12.3063 9.56873 12 9.56873C11.6937 9.56873 11.4453 9.81707 11.4453 10.1234C11.4453 10.4298 11.6937 10.6781 12 10.6781Z" fill="#6F6E6E" />
                                <path d="M15.0995 10.6781C15.4058 10.6781 15.6542 10.4298 15.6542 10.1234C15.6542 9.81707 15.4058 9.56873 15.0995 9.56873C14.7931 9.56873 14.5448 9.81707 14.5448 10.1234C14.5448 10.4298 14.7931 10.6781 15.0995 10.6781Z" fill="#6F6E6E" />
                                <path d="M8.90049 13.3235C9.20684 13.3235 9.45518 13.0751 9.45518 12.7688C9.45518 12.4625 9.20684 12.2141 8.90049 12.2141C8.59415 12.2141 8.34581 12.4625 8.34581 12.7688C8.34581 13.0751 8.59415 13.3235 8.90049 13.3235Z" fill="#6F6E6E" />
                                <path d="M12 13.3235C12.3063 13.3235 12.5547 13.0751 12.5547 12.7688C12.5547 12.4625 12.3063 12.2141 12 12.2141C11.6937 12.2141 11.4453 12.4625 11.4453 12.7688C11.4453 13.0751 11.6937 13.3235 12 13.3235Z" fill="#6F6E6E" />
                                <path d="M15.0995 13.3235C15.4058 13.3235 15.6542 13.0751 15.6542 12.7688C15.6542 12.4625 15.4058 12.2141 15.0995 12.2141C14.7931 12.2141 14.5448 12.4625 14.5448 12.7688C14.5448 13.0751 14.7931 13.3235 15.0995 13.3235Z" fill="#6F6E6E" />
                                <path d="M8.90049 15.969C9.20684 15.969 9.45518 15.7206 9.45518 15.4143C9.45518 15.108 9.20684 14.8596 8.90049 14.8596C8.59415 14.8596 8.34581 15.108 8.34581 15.4143C8.34581 15.7206 8.59415 15.969 8.90049 15.969Z" fill="#6F6E6E" />
                                <path d="M12 15.969C12.3063 15.969 12.5547 15.7206 12.5547 15.4143C12.5547 15.108 12.3063 14.8596 12 14.8596C11.6937 14.8596 11.4453 15.108 11.4453 15.4143C11.4453 15.7206 11.6937 15.969 12 15.969Z" fill="#6F6E6E" />
                            </svg>
                        </div>
                        <p className='text-base font-medium'>Events</p>
                        <p className='text-xs font-normal text-secondary-text'>The Ogun State Impact Circle holds several workshops and trainings throughout the year to develop the skills of the youth and keep them informed on the current events and issues in the state. Our calendar covers all upcoming events and provides the necessary information for those attending.</p>
                    </div>
                </Link>


                {/* second card */}
                <Link to={`/blog`} className='pl-5 pr-[30px] py-[30px] shadow-card rounded-lg max-w-[250px] h-[310px]'>
                    <div className='space-y-4'>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.3672 3.38904V19.3125C20.3672 20.8875 19.0781 22.1765 17.5078 22.1765H5.93906C5.67656 22.1765 5.42344 22.1297 5.17969 22.0593C5.54531 23.1703 6.58594 23.9859 7.81406 23.9859H19.5281C21.0609 23.9859 22.3172 22.7297 22.3172 21.1969V6.03748C22.3172 4.79529 21.4922 3.74998 20.3672 3.38904Z" fill="#6F6E6E" />
                                <path d="M16.1812 0H4.47187C2.93906 0 1.68281 1.25625 1.68281 2.78906V17.9531C1.68281 19.4859 2.93906 20.7422 4.47187 20.7422H16.1859C17.7187 20.7422 18.975 19.4859 18.975 17.9531V2.78906C18.9703 1.25625 17.7188 0 16.1812 0ZM17.0719 16.3969C17.0719 17.6016 16.0828 18.5906 14.8781 18.5906H5.66719C4.4625 18.5906 3.47344 17.6016 3.47344 16.3969V4.47188C3.47344 3.26719 4.4625 2.27812 5.66719 2.27812H14.8828C16.0875 2.27812 17.0766 3.26719 17.0766 4.47188V16.3969H17.0719Z" fill="#6F6E6E" />
                                <path d="M14.9906 5.50784H8.92969C8.67656 5.50784 8.47031 5.30159 8.47031 5.04846V5.01565C8.47031 4.76252 8.67656 4.55627 8.92969 4.55627H14.9906C15.2438 4.55627 15.45 4.76252 15.45 5.01565V5.04846C15.4547 5.30159 15.2438 5.50784 14.9906 5.50784Z" fill="#6F6E6E" />
                                <path d="M14.9906 8.21255H5.64375C5.39063 8.21255 5.18438 8.0063 5.18438 7.75317V7.72036C5.18438 7.46724 5.39063 7.26099 5.64375 7.26099H14.9953C15.2484 7.26099 15.4547 7.46724 15.4547 7.72036V7.75317C15.4547 8.00161 15.2438 8.21255 14.9906 8.21255Z" fill="#6F6E6E" />
                                <path d="M14.9906 10.9125H5.64375C5.39063 10.9125 5.18438 10.7063 5.18438 10.4531V10.4203C5.18438 10.1672 5.39063 9.96094 5.64375 9.96094H14.9953C15.2484 9.96094 15.4547 10.1672 15.4547 10.4203V10.4531C15.4547 10.7063 15.2438 10.9125 14.9906 10.9125Z" fill="#6F6E6E" />
                                <path d="M14.9906 13.6172H5.64375C5.39063 13.6172 5.18438 13.411 5.18438 13.1578V13.125C5.18438 12.8719 5.39063 12.6656 5.64375 12.6656H14.9953C15.2484 12.6656 15.4547 12.8719 15.4547 13.125V13.1578C15.4547 13.411 15.2438 13.6172 14.9906 13.6172Z" fill="#6F6E6E" />
                                <path d="M14.9906 16.3219H5.64375C5.39063 16.3219 5.18438 16.1157 5.18438 15.8625V15.8297C5.18438 15.5766 5.39063 15.3704 5.64375 15.3704H14.9953C15.2484 15.3704 15.4547 15.5766 15.4547 15.8297V15.8625C15.4547 16.111 15.2438 16.3219 14.9906 16.3219Z" fill="#6F6E6E" />
                            </svg>
                        </div>
                        <p className='text-base font-medium'>Blog</p>
                        <p className='text-xs font-normal text-secondary-text'>The Ogun State Impact Circle shares its stories and the stories of the youth that it serves. Our Blog allows us to continue to create a space for these stories and showcase the impact we are making in the community.</p>
                    </div>
                </Link>


            </div>
        </div>
    )
}

export default Activities