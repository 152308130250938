import React from 'react'
import Hero from '../../components/home/Hero'
import Carousel from '../../components/home/Carousel'
import Partners from '../../components/home/Partners'
import Activities from '../../components/home/Activities'
import AboutUs from '../../components/home/AboutUs'
import CTA from '../../components/home/CTA'

function Home() {
    return (
        <main >
            <Hero />
            <Carousel />

            <div className='2xl:max-w-[1440px] max-w-[1280px] mx-auto'>
                <Partners />
                <Activities />
                <AboutUs />
                <CTA />
            </div>

        </main>
    )
}

export default Home
