import React from 'react'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import POne from '../../assets/images/projects/Project1.png'
import PTwo from '../../assets/images/projects/Project2.png'
import PThree from '../../assets/images/projects/Project3.png'
import Pagination from '@mui/material/Pagination';

const swData = [
    {
        img: POne,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PTwo,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PThree,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PTwo,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: POne,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PThree,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PTwo,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: POne,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PTwo,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
    {
        img: PThree,
        title: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum',
        category: 'Collaboration',
        tags: ['Lorem', 'Awards'],
    },
]

function Blog() {
    return (
        <main >

            <div className='2xl:max-w-[1440px] max-w-[1280px] mx-auto 2xl:px-0 px-3 mt-[120px]'>

                <h2 className='text-4xl font-semibold text-center'>Search</h2>
                <div className='text-center mt-4 flex justify-center items-center'>
                    <input type="text" id='search' className='bg-secondary-text/20 rounded-l-xl h-[30px] pl-2 placeholder:pl-2 placeholder:text-sm text-black' placeholder='Enter keyword to search' />
                    <button className='bg-secondary-text px-10 py-0.5 rounded-r-xl text-white'>
                        <SearchOutlinedIcon />
                    </button>
                </div>


                <div className='mt-[100px] lg:flex'>
                    <div className='w-1/4 space-y-4'>
                        <div className='space-y-2 text-secondary-text'>
                            <h5 className='text-primary text-lg'>Resources</h5>
                            <div className='flex lg:block gap-4 lg:overflow-visible lg:w-full overflow-scroll w-[97vw]'>
                                <p className='text-primary'>Blog</p>
                                <p>Academy</p>
                            </div>
                        </div>
                        <div className='text-secondary-text space-y-2'>
                            <h5 className='text-primary text-lg'>Categories</h5>
                            <div className='flex lg:block gap-4 lg:overflow-visible lg:w-full overflow-scroll w-[97vw]'>
                                <p className='text-primary'>Blog</p>
                                <p>All</p>
                                <p>Economy</p>
                                <p>Community</p>
                                <p>Outreach</p>
                                <p>Bootcamp</p>
                                <p>Origins</p>
                            </div>
                        </div>
                    </div>


                    <div>
                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-10 mt-10 lg:mt-0'>
                            {swData.map((res, index) => (
                                <div key={index} className='xl:w-[250px] rounded-xl bg-card-bg'>
                                    <div className='w-full md:h-[180px]'>
                                        <img
                                            alt={`OIC ${res.title}`}
                                            src={res.img}
                                            className='w-full h-full rounded-t-xl'
                                        />

                                    </div>
                                    <div className='mt-3 p-2'>
                                        <p className='text-base font-medium text-center bg-slate-300 p-1 rounded-2xl'>{res.category}</p>
                                        <p className='text-sm font-normal mt-2 h-24'>{res.title}</p>
                                        <div className='flex gap-1' >
                                            {res.tags.map((item, index) => (
                                                <p key={index} className='text-sm bg-slate-300 px-2 rounded-2xl'>{item}</p>
                                            ))}
                                        </div>
                                        <button className='mt-8 mb-4 bg-slate-300 w-full rounded-2xl py-1'>Read More</button>
                                    </div>
                                </div>
                            ))}

                        </div>

                        <div className='flex justify-center mt-20'>
                            <Pagination count={10} variant="outlined" shape="rounded" />
                        </div>
                    </div>


                </div>

            </div>


        </main>
    )
}

export default Blog
