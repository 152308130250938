
import {
    createBrowserRouter,
} from "react-router-dom";
import Home from "./pages/landing/Home";
import Error404 from "./pages/Error404";
import AboutUs from "./pages/landing/AboutUs";
import Landing from "./pages/Landing";
import Events from "./pages/landing/Events";
import Blog from "./pages/landing/Blog";


export const router = createBrowserRouter([
    {
        path: "",
        Component: Landing,
        errorElement: <Error404 />,
        children: [
            {
                path: '/',
                Component: Home
            },
            {
                path: "/about-us",
                Component: AboutUs,
            },
            {
                path: "/events",
                Component: Events,
            },
            {
                path: "/blog",
                Component: Blog,
            },
        ]
    },
    // {
    //     path: "/about-us",
    //     Component: AboutUs,
    // },
]);

