import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid'
import CDialog from '../../common/CDialog';

import POne from '../../assets/images/projects/Project1.png'
import PTwo from '../../assets/images/projects/Project2.png'
import PThree from '../../assets/images/projects/Project3.png'

const swData = [
    {
        img: POne,
        title: 'Nigeria’s most respected Lending CEO',
        location: 'Nigerian Industry CEO awards',
        year: '2024',
    },
    {
        img: PTwo,
        title: '1000 for 1000 Affordable Housing Design Competition',
        location: 'Shelter Nigeria',
        year: '2024',
    },
    {
        img: PThree,
        title: '1st Place - South Leg on Apartment Design Competition',
        location: 'Lagos State Government',
        year: '2023',
    },
    {
        img: POne,
        title: 'Lorem Ipsum is simply dummy text of the typesetting industry.',
        location: 'Ogun State Government',
        year: '2023',
    },
    {
        img: PThree,
        title: 'Roots in a piece of classical Latin literature from 45 BC,',
        location: 'Federal Republic of Nigeria',
        year: '2023',
    },
]


var today = new Date();
var y = today.getFullYear();
var m = today.getMonth();

function renderEventContent(eventInfo) {
    return (
        <>
            <b>{eventInfo.event.title}</b>
            <br />
            <i>{eventInfo.event.extendedProps.shortDescription}</i>
        </>
    )
}
function Events() {
    // const windowSize = React.useRef([window.innerWidth, window.innerHeight]);
    // const [events, setEvents] = useState([]);
    const [eventModal, setEventModal] = React.useState(false);
    const [eventTitle, setEventTitle] = React.useState('');
    const [eventDate, setEventDate] = React.useState('');
    const [eventDescription, setEventDescription] = React.useState('');

    // useEffect(() => {
    //     // Use Effect On first Render
    //     // refill Events with dump Data or Api Call
    //     setEvents(eventsArray);
    //   }, []);

    const handleEventClick = (info) => {
        // bind with an arrow function
        console.log(`Event ID: ${info.event} Selected!`);
        console.log(info.event.end)
        setEventModal(true);
        setEventTitle(info.event.title);
        setEventDate(info.event.start.toString());
        setEventDescription(info.event.extendedProps.description);
    };
    return (
        <main >

            <div className='2xl:max-w-[1440px] max-w-[1280px] mx-auto 2xl:px-0 px-3 mt-[120px]'>
                <h2 className='text-4xl font-semibold'>Event Calendar</h2>
                <div className='mt-[70px]'>
                    {/* <h2>Width: {windowSize.current[0]}</h2> */}
                    <FullCalendar
                        plugins={[dayGridPlugin, listPlugin, timeGridPlugin]}
                        initialView="dayGridMonth"
                        // initialView={`${windowSize.current[0] < 400 ? "timeGridWeek" : "dayGridMonth"}`}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}

                        events={[
                            {
                                id: 1,
                                title: "Youth Bootcamp",
                                start: new Date(y, m, 1),
                                end: new Date(y, m, 2),
                                allDay: true,
                                description: "Youth Bootcamp",
                                shortDescription: "Youth Bootcamp..."
                            },

                            {
                                id: 2,
                                title: "Lunch meeting",
                                start: new Date(y, m, 9),
                                end: new Date(y, m, 10),
                                allDay: true,
                                description: "Lunch meeting with the kings.",
                                shortDescription: "Lunch meeting..."

                            },
                        ]}
                        headerToolbar={
                            {
                                left: "title",
                                center: "",
                                right: "prev,next today"
                            }
                        }
                    />
                </div>

                <div className='mt-[140px]'>
                    <div className='flex lg:flex-row flex-col w-full gap-x-16 px-3 xl:px-0'>
                        <div className='space-y-3 xl:w-1/6 lg:mb-0 mb-12'>
                            <h3 className='font-semibold text-center lg:text-left'>Events</h3>
                        </div>


                        <div className='xl:w-5/6'>
                            <div className='flex gap-x-7'>
                                <p className='font-bold after:w-full after:bg-primary after:h-0.5 after:block after:rounded-lg'>Ongoing</p>
                                <p>Upcoming</p>
                            </div>

                            <div className='mt-9 grid xl:grid-cols-4 lg:grid-cols-3 grid-cols-2 gap-6'>
                                {swData.map((res, index) => (
                                    <div key={index} className='xl:w-[250px]'>
                                        <div className='w-full md:h-[180px]'>
                                            <img
                                                alt={`OIC ${res.title}`}
                                                src={res.img}
                                                className='w-full h-full'
                                            />

                                        </div>
                                        <div className='mt-3'>
                                            <p className='text-base font-bold'>{res.title}</p>
                                            <p className='text-sm font-normal text-secondary-text'>{res.location}</p>
                                            <p className='text-sm font-normal text-secondary-text'>{res.year}</p>
                                        </div>
                                    </div>
                                ))}

                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <CDialog open={eventModal} title={`Event`} setModal={setEventModal}>
                <div className='my-4 space-y-4'>
                    <div>
                        <h4 className='text-xl font-bold'>Title</h4>
                        <p>{eventTitle}</p>
                    </div>
                    <div>
                        <h4 className='text-xl font-bold'>Description</h4>
                        <p>{eventDescription}</p>
                    </div>
                    <div>
                        <h4 className='text-xl font-bold'>Venue</h4>
                        <p>Ijebu-Ode</p>
                    </div>
                    <div>
                        <h4 className='text-xl font-bold'>Date</h4>
                        <p>{eventDate}</p>
                    </div>

                </div>
            </CDialog>

        </main>
    )
}

export default Events
