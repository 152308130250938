import React from 'react'
import ImpactLogo from '../../assets/logos/impact.png'
import { Link, NavLink } from "react-router-dom";


const navLinks = [
    { name: "Home", href: "/" },
    { name: "About Us", href: "/about-us" },
    { name: "Events", href: "/events" },
    { name: "Blog", href: "/blog" },
    { name: "Contact Us", href: "/contact-us" },
]

function Navbar() {

    const [open, setOpen] = React.useState(false)

    function handleClickOpen() {
        setOpen(set => !set)
    }
    return (
        <header className='sticky top-0 z-[99] 2xl:py-[30px] py-5 px-3 xl:px-0 bg-white'>
            <nav className='hidden md:flex justify-between items-center 2xl:max-w-[1440px] max-w-[1280px] mx-auto'>
                <Link to={`/`} className='w-[220px]'>
                    <img src={ImpactLogo} alt="" />
                </Link>
                <div>
                    <ul className='flex justify-between items-center gap-x-8'>
                        {navLinks.map((res, index) => (
                            <li key={index}>
                                <NavLink to={res.href} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "font-bold inline-block relative after:absolute after:left-0 after:-bottom-1 after:h-1 after:bg-primary after:w-full after:rounded-lg" : "inline-block relative after:absolute after:left-1/2 after:-bottom-1 after:h-1 after:bg-primary after:w-0 after:transition-all after:duration-300 after:ease-in-out after:hover:w-full after:hover:left-0 after:rounded-lg"}>
                                    {res.name}
                                </NavLink>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='hidden lg:block'>
                    <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg'>Join the movement</button>
                </div>
            </nav>

            <nav className='md:hidden flex justify-between items-center'>
                <div className='w-[150px]'>
                    <img src={ImpactLogo} alt="" />
                </div>

                <div>
                    <button className={`flex flex-col justify-between items-baseline p-0 cursor-pointer w-[30px] h-5 relative after:absolute after:inset-0 after:m-auto after:h-0.5 after:bg-black ${open ? 'after:w-0' : 'after:w-[70%]'} transition-all ease-in-out after:transition-all after:ease-in-out`} onClick={handleClickOpen}>
                        <span className={`h-0.5 bg-black ${open ? 'w-[80%] rotate-45 translate-y-2' : 'w-full'} transition-all ease-in-out`}></span>
                        <span className={`h-0.5 bg-black ${open ? 'w-[80%] -rotate-45 -translate-y-2  ' : 'w-full'} transition-all ease-in-out`}></span>
                    </button>


                </div>
            </nav>

            <div className={`absolute w-screen md:hidden left-0 bg-white ${open ? 'h-[94vh]' : 'h-0 '} transition-all ease-in-out `}>
                {open &&
                    <div className='flex flex-col justify-between h-full py-20 px-5 '>
                        <ul className='text-center text-xl space-y-10'>
                            {navLinks.map((res, index) => (
                                <li className='w-full ' onClick={() => setOpen(false)}>
                                    <NavLink to={res.href} className={({ isActive, isPending }) => isPending ? "pending" : isActive ? "w-full text-primary font-bold inline-block relative after:absolute after:left-0 after:-bottom-1 after:h-1 after:bg-primary/70 after:w-full after:rounded-lg" : "inline-block relative after:absolute after:left-1/2 after:-bottom-1 after:h-1 after:bg-primary after:w-0 after:transition-all after:hover:w-full after:hover:left-0 after:rounded-lg"}>
                                        {res.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                        <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg'>Join the movement</button>
                    </div>
                }
            </div>
        </header>
    )
}

export default Navbar

