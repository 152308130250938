import React from 'react'
import Partner1 from '../../assets/images/partner1.png'
import Partner2 from '../../assets/images/partner2.png'
import Partner3 from '../../assets/images/partner3.png'

function Partners() {
  return (
    <div className='mt-10'>
      <p className='text-secondary-text text-[15px] text-center'>Partners</p>

      <div className='flex flex-wrap items-center justify-between p-4 mt-9 gap-y-7 '>
        <div className='w-1/2 sm:w-1/5'><img src={Partner1} alt="" className='w-full p-8' /></div>
        <div className='w-1/2 sm:w-1/5'><img src={Partner2} alt="" className='w-full p-8' /></div>
        <div className='w-1/2 sm:w-1/5'><img src={Partner3} alt="" className='w-full p-8' /></div>
        <div className='w-1/2 sm:w-1/5'><img src={Partner1} alt="" className='w-full p-8' /></div>
        <div className='w-1/2 sm:w-1/5'><img src={Partner2} alt="" className='w-full p-8' /></div>

      </div>
    </div>
  )
}

export default Partners
