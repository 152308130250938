import React from 'react'
import Hero from '../../components/about/Hero'
import Projects from '../../components/about/Projects'
import Stakeholders from '../../components/about/Stakeholders'
import Awards from '../../components/about/Awards'

function AboutUs() {
    return (
        <main >
            <Hero />

            <div className='2xl:max-w-[1440px] max-w-[1280px] mx-auto'>
                <Projects />
                <Stakeholders />
                <Awards />

            </div>

        </main>
    )
}

export default AboutUs