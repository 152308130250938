import React from 'react'
import { useRouteError } from "react-router-dom";

function Error404() {
    const error = useRouteError();
    console.error(error);
    return (
        <div class="h-screen flex justify-center items-center text-center">
            <div className='space-y-10'>
                <h1>Oops!</h1>
                <p>Sorry, This page is not ready yet.</p>
                <p>
                    <i>{error.statusText || error.message}</i>
                </p>
            </div>
        </div>
    )
}

export default Error404
