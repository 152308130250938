import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import One from "../../assets/images/1.png"
import Two from "../../assets/images/2.png"
import Three from "../../assets/images/3.png"
import Four from "../../assets/images/4.png"
import Five from "../../assets/images/5.png"
import Six from "../../assets/images/6.png"
import Seven from "../../assets/images/7.png"
import Eight from "../../assets/images/8.png"


const swiperImages = [One, Two, Three, Four, Five, Six, Seven, Eight]

export default function Carousel() {
    return (
        <div className='py-[70px]'>
            <Swiper
                // spaceBetween={30}
                // slidesPerView={5}
                spaceBetween={20}
                slidesPerView={2}
                modules={[Autoplay]}
                autoplay={true}
                breakpoints={{
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 40,
                    },
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 50,
                    },

                }}
                // onSlideChange={() => console.log("slide change")}
                // onSwiper={(swiper) => console.log(swiper)}
                className={`relative overflow-hidden before:swiper-before after:swiper-after`}
            >
                {swiperImages.map((res, index) => (
                    <SwiperSlide>
                        <div className='w-[250px] h-[350px] max-[370px]:w-[120px] max-[370px]:h-[350px] max-[450px]:w-[195px] max-[450px]:h-[350px] sm:w-[250px] sm:[400px] 2xl:w-[360px] 2xl:h-[450px]'>
                            <img
                                alt=""
                                key={index}
                                src={res}
                                className='w-full h-full'
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
