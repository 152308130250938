import React from 'react'
import Mission from '../../assets/images/mission.png'
import Vision from '../../assets/images/vision.png'
function AboutUs() {
    return (
        <section className='mt-[100px] px-3 2xl:px-0'>
            <div className='space-y-[45px]'>
                <h2 className='text-black font-semibold text-2xl md:text-5xl text-center'>About Us</h2>

                <p className='text-xs font-normal  lg:text-center text-justify'>The Ogun State Impact Circle seeks to enable and empower the youth of Ogun State to create an even wider circle of awareness and inclusion between them and the relevant stakeholders within the state. Through the platform, we provide resources and learning experiences that create the necessary knowledge and opportunities for them. </p>

                {/* <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg font-bold'>Learn More</button> */}
            </div>

            <div className='mt-[120px] flex flex-col sm:flex-row justify-center items-center gap-x-16 max-w-[875px] mx-auto gap-y-8'>

                <div className='sm:w-1/2'>
                    <h4 className='mb-3 font-semibold'>Our Mission</h4>
                    <p className='mb-8 text-xs'>The Ogun State Impact Circle seeks to enable and empower the youth of Ogun State to create an even wider circle of awareness and inclusion between them and the relevant stakeholders within the state. Through the platform, we provide resources and learning experiences that create the necessary knowledge and opportunities for them. </p>
                    <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg font-bold hidden sm:block'>Learn More</button>

                </div>
                <div>
                    <img src={Mission} alt="" className='rounded-lg' />
                </div>

            </div>


            <div className='mt-[130px] max-w-[840px] mx-auto text-center'>
                <p className='sm:text-[32px] text-[28px] font-medium sm:leading-[45px] leading-[34px]'>The Ogun State Impact Circle is helping shape the youth into tomorrow's leaders through various types of leadership programs. These programs are designed to build the necessary skills and provide the resources and learning experiences needed for the youth to be successful.</p>
            </div>


            <div className='mt-[120px] flex flex-col sm:flex-row justify-center items-center gap-x-16 max-w-[875px] mx-auto gap-y-8'>

                <div>
                    <img src={Vision} alt="" className='rounded-lg' />
                </div>

                <div className='sm:w-1/2'>
                    <h4 className='mb-3 font-semibold'>Our Vision</h4>
                    <p className='mb-8 text-xs'>The Ogun State Impact Circle seeks to enable and empower the youth of Ogun State to create an even wider circle of awareness and inclusion between them and the relevant stakeholders within the state. Through the platform, we provide resources and learning experiences that create the necessary knowledge and opportunities for them. </p>
                    <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg font-bold hidden sm:block'>Learn More</button>

                </div>


            </div>

        </section>
    )
}

export default AboutUs
