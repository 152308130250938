import React from 'react'
import Navbar from '../components/home/Navbar'
import Footer from '../components/home/Footer'
import { Outlet } from 'react-router-dom'
import ScrollToTop from '../helpers/ScrollToTop'


function Landing() {
    return (
        <>

            <ScrollToTop />
            <Navbar />
            <Outlet />
            <Footer />
        </>
    )
}

export default Landing
