import React from 'react'
import HeroImg from '../../assets/images/aboutus_hero.png'

function Hero() {
    return (
        <section className='2xl:max-w-[1440px] max-w-[1280px] mx-auto'>
            <div className='flex items-center justify-between relative md:py-0 py-36 '>
                <h1 className='font-normal lg:text-6xl md:text-5xl text-4xl md:text-left text-center lg:leading-[85px] md:w-1/2 px-3 xl:px-0'>Carving tomorrows leaders today.</h1>

                <div className='md:flex hidden justify-center items-center sm:mt-5 mt-10 text-center mx-auto gap-x-5 h-[560px] lg:w-[470px] w-1/2 px-3 xl:px-0'>
                    <img src={HeroImg} alt="" className='w-full h-full' />
                </div>

                <div className='absolute bottom-0 flex justify-between md:w-[80%] w-full bg-white py-4 px-5 rounded-t-lg shadow md:shadow-none'>
                    <div>
                        <h3 className='md:text-2xl text-xl font-bold text-center '>52+</h3>
                        <p className='md:text-xs text-[10px] md:font-normal font-light'>Projects Completed</p>
                    </div>
                    <div>
                        <h3 className='md:text-2xl text-xl font-bold text-center '>106+</h3>
                        <p className='md:text-xs text-[10px] md:font-normal font-light'>Community Outreach</p>
                    </div>
                    <div>
                        <h3 className='md:text-2xl text-xl font-bold text-center '>12000+</h3>
                        <p className='md:text-xs text-[10px] md:font-normal font-light'>Individuals Trained</p>
                    </div>
                </div>
            </div>

            <div className='mt-12 md:text-center text-justify md:px-20 px-3'>
                <p className='md:text-xl font-medium'>Ogun State Impact Circle (OIC) is a movement and a transformative and disruptive platform aimed at creating awareness and inclusion among the next generation of leaders who are indigenes of Ogun State. The goals are to educate, engage, and encourage participation in community development, through empowerment, and involvement governance.</p>

            </div>
        </section>
    )
}

export default Hero