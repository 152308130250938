import React from 'react'

function CTA() {
    return (
        <section className='mt-[140px]'>


            <div className='space-y-[38px] text-center max-w-[840px] mx-auto px-3 md:px-0'>
                <h2 className='text-black font-semibold text-2xl md:text-5xl'>Making a Change</h2>
                <p className='text-xs font-normal'>The Ogun State Impact Circle is helping shape the youth into tomorrow's leaders through various types of leadership programs. These programs are designed to build the necessary skills and provide the resources and learning experiences needed for the youth to be successful.</p>

                <button className='bg-primary text-secondary px-5 py-2.5 rounded-lg font-bold'>Join Us</button>
            </div>


        </section>
    )
}

export default CTA